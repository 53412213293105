import React, { useState } from "react";
import "./App.css";
import Landing from "./components/Landing";
import PenpalProject from "./components/PenpalProject";
import SoundExchange from "./components/SoundExchange";
import DonateHeart from "./components/DonateHeart";
import Navbar from "./components/Navbar";
import M_Navbar from "./components/MobileVersion/Navbar";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, Switch } from "react-router";

function App() {
  const history = useHistory();
  const [lang, setLang] = useState('zh')
  const protectImage = () => {
    document.addEventListener('contextmenu', event => event.preventDefault());
  }

  const handleSwitchLang = () => {
    const currentLang = lang == 'zh' ? 'en' : 'zh'
    setLang(currentLang)
  }

  return (
    <div className="App" onMouseDown={protectImage}>
      <img
        className="logo"
        id="logo-img"
        src={require("./images/title-lg.png")}
      />
      <Switch>
        <Router>
          <main>
            <Container fluid className="m_Navbar">
              <Row>
                <Col>
                  <M_Navbar history={history} lang={lang} handleSwitchLang={handleSwitchLang}/>
                </Col>
              </Row>
            </Container>
            <div className="nav">
              <Navbar lang={lang} history={history} handleSwitchLang={handleSwitchLang}/>
            </div>
            <div id="view">
              <Route exact path="/still-life-objects" 
              component={() => <Landing lang={lang}/>}
              />
              <Route
                exact
                path="/still-life-objects/paper-tearing-penpal-therapy"
                component={() => <PenpalProject lang={lang}/>}
              />
              <Route
                exact
                path="/still-life-objects/1-hour-before-sleep-audio-exchange"
                component={() => <SoundExchange lang={lang}/>}
              />
              <Route
                exact
                path="/still-life-objects/fold-a-banknote-twice-as-good"
                component={() => <DonateHeart lang={lang}/>}
              />
              {/* <Route 
                path="*"
                component={PenpalProject}
              /> */}
            </div>
          </main>
        </Router>
      </Switch>
    </div>
  );
}

export default App;
