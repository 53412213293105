import React, { useState, useEffect } from "react";
import { Flipper, Flipped } from "react-flip-toolkit";
import shuffle from "lodash.shuffle";
import "./style.css";
import { gsap } from "gsap";
import { Container, Row, Col } from "react-bootstrap";

/**
 * @author
 * @function PenpalProject_page2
 **/

const PenpalProject_page2 = (props) => {
  const { fullpageprops, lang } = props
  const paper1 = require("../images/paper/1-orange.png");
  const paper2 = require("../images/paper/1-pink.png");
  const paper3 = require("../images/paper/2.png");
  const paper4 = require("../images/paper/3-large.png");
  const paper5 = require("../images/paper/3-small.png");
  const paper6 = require("../images/paper/4.png");
  const paper7 = require("../images/paper/5.png");
  const paper8 = require("../images/paper/6-green.png");
  const paper9 = require("../images/paper/6-yellow.png");
  const paper10 = require("../images/paper/7-green.png");
  const paper11 = require("../images/paper/7-orange.png");
  const paper12 = require("../images/paper/8.png");
  const paper13 = require("../images/paper/9.png");
  const papers = [
    paper1,
    paper2,
    paper3,
    paper4,
    paper5,
    paper6,
    paper7,
    paper8,
    paper9,
    paper10,
    paper11,
    paper12,
    paper13,
  ];

  const [data, setData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]);
  const shuffleList = () => setData(shuffle(data));
  const [isShowDes, setIsShowDes] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      shuffleList();
    }, 5000);
  });

  const addDescription = () => {
    if (isShowDes && window.innerWidth > 1200) {
      gsap.fromTo(
        ".description",
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 1 }
      );
      setIsShowDes(false);
    }
  };

  return (
    <div className="out" onMouseOver={addDescription}>
      <div className="penpal-project-page2">
        <div className="view">
          <Container fluid="md">
            <Row className="description-row">
              <Col
                className="col description-col"
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                  <div className="description">
                  {lang == 'zh' ?
                  <div className="chinese">
                    <h4 className="programme-title">「撕紙治療（筆友）計劃」</h4>
                    <h4 className="programme-title-2">—心安，來自與陌生人建立莫明的情感連繫</h4>
                    <p className="programme-intro">用撕代替寫，把數字看作文字：每片碎紙，形狀、顏色和數字，在分佈與排列之間構成內容，互通彼此。香港藝術家團隊 L sub以「心安之物」於港日兩地推行「撕紙治療（筆友）計劃」。從撕碎到聚合帶出治癒效果，促進人與人、不同文化和語言之間的溝通和連繫。</p>
                    <p className="programme-intro">現在就請你來撕一封信給一個朋友：</p>
                    <ol className="programme-intro">
                      <li>想著一個朋友，把一張A4紙（或任何色紙）撕碎。</li>
                      <li>注意形狀，在每一張紙碎隨意寫上數字。</li>
                      <li>把碎紙寄給你的朋友，請他排列和解讀內容。並以相同方式回信。</li>
                      <li>歡迎把照片紀錄<a onClick={() => fullpageprops.moveTo(4)}>傳予L sub</a>，作為展覽內容。</li>
                    </ol>
                    <p className="programme-intro">謝謝你們的參與！</p>
                  </div>
                    :
                    <div className="english">
                      <h4 className="programme-title">‘Paper Tearing Pen-pal Therapy'</h4>
                      <h4 className="programme-title-2">— To pursue peace of mind by creating spiritual connection with others.</h4>
                      <p className="programme-intro">Hong Kong artist collective L sub introduces an alternative way of communication – to connect with a pen-pal through a letter with no letters, and ciphers that need no deciphering. This ‘Paper Tearing Pen-pal Therapy' is a public campaign held between Hong Kong and Japanese communities. From paper tearing to rearranging the fragments, the campaign brings healing effect and promotes communication and connection between people with different cultures and languages.</p>
                      <p className="programme-intro">Now it’s your turn to pick up a piece of paper and tear it into fragments to a friend: </p>
                      <ol className="programme-intro">
                        <li>Tear a sheet of colour paper enclosed into fragments. While tearing, think of the recipient. </li>
                        <li>Pay attention to fragments’ shapes and scribble numerical digits in any combination or sequence on all fragments.</li>
                        <li>Mail the paper fragments to your friend with the enclosed envelope. The recipient is free to read and interpret the “letter” in their own way and reply in the same fashion.</li>
                        <li>You are invited to share the process and result with <a onClick={() => fullpageprops.moveTo(4)}>L sub</a> through sending the photo documentation of the paper fragment exchange to still.life.objects@gmail.com The documentation may become part of the exhibition.</li>
                      </ol>
                      <p className="programme-intro">Thank you for your participation!</p>
                    </div>
                    }
                  </div>
              </Col>
            </Row>
          </Container>

          <div className="shuffle-animation">
            <Flipper flipKey={data.join("")}>
              <br />
              {data.map((d) => (
                <Flipped key={d} flipId={d}>
                  <div className="paper">
                    <img src={papers[d - 1]} />
                  </div>
                </Flipped>
              ))}
            </Flipper>
          </div>
        </div>
      </div>
      <div className="down-arrow" />
    </div>
  );
};

export default PenpalProject_page2;
