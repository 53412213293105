import React, {useState} from 'react';
import './style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import DelayLink from '../../DelayLink';

const M_Navbar = ({lang, handleSwitchLang}) => {
    const [open, setOpen] = useState(false)

    const handleToggle = () => {
        setOpen(!open)
    }

    const switchLang = () => {
        setOpen(false)
        handleSwitchLang()
    }

    return (
        <div>
            <nav role="navigation">
                <div id="menuToggle">
                    <input type="checkbox" checked={open} onClick={handleToggle}/>
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul id="menu">
                        <li><a className="delaylink" href="/still-life-objects/">{lang == 'zh' ? '心安之物' : 'Still life objects'}</a></li>
                        <li><a className="delaylink" href="http://l-sub.org/still-life-objects-exhibition/" target="_blank">{lang == 'zh' ? '周末禮讚' : 'Interactive Exhibition'}</a></li>
                        <li><a className="delaylink" href="http://l-sub.org/still-life-objects-festival/" target="_blank">{lang == 'zh' ? '十日祭' : 'Ten-day Festival'}</a></li>
                        <li><DelayLink handleToggle={handleToggle} delay={0} to="/still-life-objects/paper-tearing-penpal-therapy">{lang == 'zh' ? '撕紙治療(筆友)計劃' : 'Paper Tearing Pen-pal Therapy'}</DelayLink></li>
                        <li><DelayLink handleToggle={handleToggle} delay={0} to="/still-life-objects/1-hour-before-sleep-audio-exchange">{lang == 'zh' ? '睡前聲音交換' : '1-hour Before Sleep Audio Exchange'}</DelayLink></li>
                        <li><DelayLink handleToggle={handleToggle} delay={0} to="/still-life-objects/fold-a-banknote-twice-as-good">{lang == 'zh' ? '摺出善意' : 'Fold a Banknote. Twice as Good'}</DelayLink></li>
                        <li><a onClick={switchLang}>{lang == 'zh' ? 'Eng' : '中文'}</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default M_Navbar;
