import React from "react";
import { Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import "./style.css";
import ArchiveElement from './archive_element';
import element from './element_db';

/**
 * @author
 * @function SoundExchange_page4
 **/

const SoundExchange_page4 = ({ lang }) => {
  return (
    <div className="sound-exchange-page4">
      <div className="up-arrow" />
      <div className="container" onLoad={() => {
        if(window.innerWidth > 1200) {
          gsap.fromTo(
            ".container",
            { y: 50, opacity: 0 },
            { y: 0, opacity: 1, duration: 1 }
          );
        }
      }}>
        {/*col4*/}
        <Row className="specific-row">
          {element.map((e) => <Col md="4" sm="6" xs="12" key={e.name}><ArchiveElement props={e} /></Col>)}
        </Row>
      </div>
    </div>
  );
};

export default SoundExchange_page4;