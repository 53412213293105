import React from "react";
import "./style.css";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import Slider from "./slider";

const DonateHeart_page2a = ({lang}) => {
  return (
    <div className="donate-heart-page2a">
      <Container>
        <Row id="row-carousel">
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            id="col-carousel"
            className="col"
          >
            <Slider id="slider" />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="slo-content-parabox col"
          >
            {lang == 'zh' ?
            <div className="chinese">
              <h4 className="slo-content-h4">周先生善心回饋</h4>
              <div className="slo-content-divider"></div>
              <p className="slo-content-p">
                早晨寧靜街道上，他穿著裇衫西褲走到綠色鐵皮屋前，熟練地攤開檔內的床鋪被單、掛起抱枕內衣。他是周先生，
                是賣物的小販，是送暖的行善者。他把東西賣出去賺錢，再把賺回來的錢送出去。賣與送的流轉之間，他照顧自己
                之餘，不知不覺也幫助了他人過日子。
              </p>
              <p className="slo-content-p">
                過去三十年，周先生收舖之後，總會專程探訪患有精神病的無家者，送上所需食用，親力親為絕無怨言。無家者有
                困難時或會走到舖頭求救，他也毫不猶豫地打開銀包，給予三幾百元。無兒無女本可無牽無掛，但他卻顧念無親無
                故的無家者。行有餘力，顧己及人，周先生心安自然富足的善行，啟發我們發起「善心的回饋」計劃，將一個人的
                喜樂配對出去。
              </p>
              <p className="slo-content-p">
                展場二樓的被單購自周先生的攤檔，並繡上「睡前一小時聲音交換計劃」參加者的資料。用柔軟的物料盛載陌生人
                的故事，如同周先生輕輕幫一把，支援萍水相逢的無家者。
              </p>
            </div>
            :
            <div className="english">
              <h4 className="slo-content-h4">Mr Chow’s “Fold a Banknote. Twice as Good”</h4>
              <div className="slo-content-divider"></div>
              <p className="slo-content-p">
                On a quiet street in early morning, a man in shirt and trousers approaches his green tin kiosk, deftly displays the bedding items in his stall and hangs up the cushions and underwear.
              </p>
              <p className="slo-content-p">
                He is Mr Chow, a vendor and beneficent giver. Mr Chow’s altruistic deeds have attracted media attention, and his stories have often been told on various public media. They inspired us to create the “Walking Meet Twice/Double Happiness” workshop as well as the “Fold a Banknote. Twice as Good” charity-matching project.
              </p>
            </div>
            }
          </Col>
        </Row>
      </Container>
      <div className="down-arrow" />
    </div>
  );
};

export default DonateHeart_page2a;
