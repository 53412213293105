import React, { useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import DonateHeart_page1 from "./page1";
import DonateHeart_page2 from "./page2";
import DonateHeart_page2a from "./page2a";
import DonateHeart_page3 from "./page3";

function DonateHeart({lang}) {

  useEffect(() => {
  });

  return (
    <ReactFullpage
      //fullpage options
      licenseKey={"YOUR_KEY_HERE"}
      scrollingSpeed={1000} /* Options here */
      responsiveWidth={1200}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <DonateHeart_page1 />
            </div>
            <div className="section">
              <DonateHeart_page2 fullpageprops={fullpageApi} lang={lang}/>
            </div>
            <div className="section">
              <DonateHeart_page2a lang={lang}/>
            </div>
            <div className="section">
              <DonateHeart_page3 lang={lang}/>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default DonateHeart;
