import React from 'react';
import "fullpage.js/vendors/scrolloverflow";
import ReactFullpage from '@fullpage/react-fullpage';
import SoundExchange_page1 from './page1';
import SoundExchange_page2 from './page2';
import SoundExchange_page2a from './page2a';
import SoundExchange_page3 from './page3';
import SoundExchange_page4 from './page4';
import './style.css';

function SoundExchange({lang}) {
    return (
        <ReactFullpage
            //fullpage options
            licenseKey={'YOUR_KEY_HERE'}
            scrollingSpeed={1000} /* Options here */
            //From <1200 to >1200 white screen
            responsiveWidth={1200}
            scrollOverflow={true}
            normalScrollElements={'.form-container, .slo-content-parabox'}
            // afterResize={(width, height)=>{console.log(`width: ${width}, height: ${height}`)}}
            render={({ state, fullpageApi }) => {
                return (
                    <div>
                        <ReactFullpage.Wrapper>
                            <div className="section">
                                <SoundExchange_page1 />
                            </div>
                            <div className="section">
                                <SoundExchange_page2 fullpageprops={fullpageApi} lang={lang}/>
                            </div>
                            <div className="section">
                                <SoundExchange_page2a lang={lang}/>
                            </div>
                            <div className="section">
                                <SoundExchange_page3 lang={lang}/>
                            </div>
                            <div className="section fp-scrollable fp-auto-height-responsive">
                                <SoundExchange_page4 lang={lang}/>
                            </div>
                        </ReactFullpage.Wrapper>
                    </div>
                );
            }}
        />
    )
}

export default SoundExchange;
