import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import '../App.css';

const DelayLink = (props) => {
    let [isTimeOut, setIsTimeout] = useState(false);
    const [timeoutId, setTimeoutId] = React.useState(null);
    let history = useHistory();
    const { replace, to, external, delay, onDelayStart, onDelayEnd, handleToggle, children } = props;
    useEffect(() => {
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [timeoutId]);
    useEffect(() => {
        if (isTimeOut) {
            let delayTimeOut = setTimeout(() => {
                if (replace) {
                    history.replace(to);
                } else {
                    history.push(to);
                }
                onDelayEnd();
                setIsTimeout(false);
            }, delay);
            setTimeoutId(delayTimeOut);
            handleToggle && handleToggle()
        }
    }, [delay, history, onDelayEnd, replace, to, isTimeOut]);
    const handleClick = useCallback(
        (e) => {
            onDelayStart(e, to);
            if (e.defaultPrevented) {
                return;
            }
            e.preventDefault();
            setIsTimeout(true);
        },
        [to, onDelayStart]
    );
    
    if(external) {
        return (
            <a className="delaylink"
                href={`${props.to}`} target="_blank">
                {children}
            </a>
        );
    }
    else{
        return (
            <Link
                className="delaylink"
                to={`${props.to}`}
                delay={props.delay}
                onClick={handleClick}
                replace={props.replace}>
                {children}
            </Link>
        );
    }
};
DelayLink.prototype = {
    delay: PropTypes.number,
    to: PropTypes.string.isRequired,
    onDelayStart: PropTypes.func,
    onDelayEnd: PropTypes.func,
    replace: PropTypes.bool
};
DelayLink.defaultProps = {
    delay: 0,
    onDelayStart: () => { },
    onDelayEnd: () => { }
};
export default DelayLink;