import React from 'react';
import './style.css';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import Slider from './slider';

const SoundExchange_page2a = ({lang}) => {
    return (
        <div className="sound-exchange-page2a">
            <Container>
                <Row id="row-carousel">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} id="col-carousel" className="col">
                        <Slider id="slider" />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className="slo-content-parabox col">
                        {lang == 'zh' ?
                        <div className="chinese">
                            <h4 className="slo-content-h4">寶鳯與晚安曲</h4>
                            <div className="slo-content-divider"></div>
                            <p className="slo-content-p">
                                斜陽夕照廟堂中，她手持法器，叮叮噹噹敲出節奏；緩緩跪在佛像前，唸唸有詞誦唱經文，這是她的「晚課」。
                                她是寶鳳，是佛堂的打理人，兼掌所屬的安老院及齋菜小館。由朝到晚，她工作排得滿滿，但從不抱怨，懷著慈
                                悲的心繼續侍奉。每天黃昏的 「晚課」風雨不改，象徵她一天辛勞的結束。對於自小學佛的寶鳳來說，這是莊嚴
                                的敬拜儀式，但旁人看來卻如「晚安曲」。敲打法器的聲音，誦唱經文的文字，像是有曲有詞的樂章，讓她放鬆心
                                神，安穩地進入休息狀態。
                            </p>
                            <p className="slo-content-p">
                                受寶鳳的「晚課」啟發，我們向香港長者收集「心安之物」，並以「心安之物」發出的聲音，譜製成「晚安曲」；
                                並發起「睡前一小時聲音交換計劃」，分享「心安之聲」。哪怕是平平無奇的環境雜訊，閉上眼靜聽有如置身熟悉
                                的場景，燙貼內心躁動的波紋。心安源於日常。我們在香港及日本家庭最尋常的角落，收集並交換彼此最平凡的聲音。
                            </p>
                        </div>
                        :
                        <div className="english">
                            <h4 className="slo-content-h4">Ms Lee and the Buddhist “Evensong”</h4>
                            <div className="slo-content-divider"></div>
                            <p className="slo-content-p">
                                The setting sun shines into the nunnery. Ms Lee beats a religious vessel rhythmically, kneels slowly before the statue of the Buddha and chants the sutra – her “Evensong”. 
                                Ms Lee manages the nunnery as well as the old people’s home and the vegetarian eatery that it owns. Inspired by Ms Lee’s “Evensong”, we have collected “still life objects” from Hong Kong senior citizens, and used the sounds made by these objects to compose an evensong to be played daily before the exhibition hall closes. 
                            </p>
                            <p className="slo-content-p">
                                We have also launched a “One-hour Before Sleep Audio Exchange” activity to share “sounds of peace of mind” among the participants in Hong Kong and in Japan.
                            </p>
                        </div>
                        }
                    </Col>
                </Row>
            </Container>
            <div className="down-arrow" />
        </div>
    );
}

export default SoundExchange_page2a;
