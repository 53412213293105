import React, { useState } from "react";
import axios from 'axios'
import Dropzone, {useDropzone} from 'react-dropzone'
import _ from 'lodash'
import "./style.css";

const PenpalForm = ({lang}) => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [image, setImage] = useState()
  const [receipt, setReceipt] = useState()
  const [isImageDragging, setImageDragging] = useState(false)
  const [isReceiptDragging, setReceiptDragging] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitFinish, setSubmitFinish] = useState(false)
  const [error, setError] = useState()

  const validateSubmit = () => {
    const emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const obj = { name, email }
    let errorObj = null
    _.each(obj, (value, key) => {
        if(value === '')
          errorObj = {
            ...errorObj,
            ...{
              [key]: lang == 'zh' ? '請填上資料' : 'Please fill in the field'
            }
          }
    })
    if(!emailFormat.test(String(email).toLowerCase()))
      errorObj = {
        ...errorObj,
        ...{
          email: lang == 'zh' ? '電郵地址格式錯誤' : 'Email address format is incorrect'
        }
      }
    if(!image)
    {
      errorObj = {
        ...errorObj,
        ...{
          image: lang == 'zh' ? '請上傳圖片' : 'Please upload photo'
        }
      }
    }
    if(!receipt)
    {
      errorObj = {
        ...errorObj,
        ...{
          receipt: lang == 'zh' ? '請上傳圖片' : 'Please upload photo'
        }
      }
    }
    if(errorObj)
    {
      console.log('errors', errorObj)
      setError(errorObj)
      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('going to submit form')

    if(validateSubmit()) {
      setIsSubmitting(true)
      console.log('no errors')
      const formHTML = e.target
      var formData = new FormData(formHTML)
      formData.append('image', image)
      formData.append('receipt', receipt)
      formData.append('form', 'donateHeartForm')
      const response = await axios.post('http://l-sub.org/still-life-objects/backend/controllers/submitForm.php', formData, {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      if(response.status === 200)
      {
        setIsSubmitting(false)
        setSubmitFinish(true)
      }
    }
  } 

  return (
      <form id="donateHeartForm" onSubmit={handleSubmit}>
        <div className="submit-container">
          <p className="title">{lang == 'zh' ? '上載對摺的銀紙照片' : 'Upload the Photo of a Folded Banknote'}</p>
          <div className="submit-form-container two-form">
            {!submitFinish ?
              <div className="form-container">
                  <div className="row">
                    <div className={`file-container col-12 col-sm-6 ${isImageDragging && 'isDragging'} ${error && error.image ? 'error' : ''}`}>
                        <Dropzone accept="image/*" minSize={0} maxSize={5242880} multiple={false}
                            onDrop={acceptedFiles => {
                            setImage(acceptedFiles[0])
                            setImageDragging(false)
                            setError({
                              ...error, 
                              ...{ image: null }
                            })
                         }} onDragEnter={() => setImageDragging(true)} onDragLeave={() => setImageDragging(false)}
                         >
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <div className="two-form-title">{lang == 'zh' ? '摺銀紙相' : 'Folded Banknote'}</div>
                                    <input id="file" type="file" className="foo" {...getInputProps()} />
                                    <div id="fileName">{image && image.name ? image.name : lang == 'zh' ? '未選擇任何檔案' : 'No file is selected'}</div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案格式' : 'File Format'}</span>
                                        <div className="form-text">JPG, GIF, PNG, HEIC</div>
                                    </div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案大小' : 'File size'}</span>
                                        <div className="form-text">{lang == 'zh' ? '不大於5mb' : 'Within 5mb'}</div>
                                    </div>
                                    {error && error.image ?
                                    <div className="help-block">{error.image}</div>
                                    : null}
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className={`file-container col-12 col-sm-6 ${isReceiptDragging && 'isDragging'} ${error && error.receipt ? 'error' : ''}`}>
                        <Dropzone accept="image/*" minSize={0} maxSize={5242880} multiple={false}
                            onDrop={acceptedFiles => {
                            setReceipt(acceptedFiles[0])
                            setReceiptDragging(false)
                            setError({
                              ...error, 
                              ...{ receipt: null }
                            })
                         }} onDragEnter={() => setReceiptDragging(true)} onDragLeave={() => setReceiptDragging(false)}
                         >
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <div className="two-form-title">{lang == 'zh' ? '捐助機構之單據' : 'Donation Receipt'}</div>
                                    <input id="file" type="file" className="foo" {...getInputProps()} />
                                    <div id="fileName">{receipt && receipt.name ? receipt.name : lang == 'zh' ? '未選擇任何檔案' : 'No file is selected'}</div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案格式' : 'File format'}</span>
                                        <div className="form-text">JPG, GIF, PNG, HEIC</div>
                                    </div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案大小' : 'File size'}</span>
                                        <div className="form-text">{lang == 'zh' ? '不大於5mb' : 'Within 5mb'}</div>
                                    </div>
                                    {error && error.receipt ?
                                    <div className="help-block">{error.receipt}</div>
                                    : null}
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </div>
                <div className="input-container">
                  <div className={`input-label ${error && error.name ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '你的名字' : 'Your name'}<sup>*</sup></label>
                    <input type="text" name="name" className="form-control" id="inputName" onChange={e => { setError({ ...error, ...{ name: null}}); setName(e.target.value)}}/>
                    {error && error.name ?
                    <div className="help-block">{error.name}</div>
                    :null}
                  </div>
                  <div className={`input-label ${error && error.email ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '電郵' : 'Email address'}<sup>*</sup></label>
                    <input type="text" name="email" className="form-control" id="inputEmail" onChange={e => { setError({ ...error, ...{ email: null}}); setEmail(e.target.value)}}/>
                    {error && error.email ?
                    <div className="help-block">{error.email}</div>
                    :null}
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn-sumbit">
                      <span id="txt-beforeUpload">
                        {isSubmitting ? 
                          lang == 'zh' ? '上載中...' : 'Uploading...'
                        : 
                          lang == 'zh' ? '確認上載' : 'Upload'}
                      </span>
                  </button>
                </div>
              </div>
              :
              <div className="finish-container">
                  <div>{lang == 'zh' ? '你已成功上傳' : 'Upload successfully'}</div>
              </div>
            }
          </div>
        </div>
      </form>
  )
}

export default PenpalForm