import React, { useEffect } from "react";
import SubmitForm from "../../SubmitForm";
import PenpalForm from '../../SubmitForm/PenpalForm'
import "./style.css";

/**
 * @author
 * @function PenpalProject_page3
 **/

const PenpalProject_page3 = ({lang}) => {
  useEffect(() => {});
  return (
    <div className="out penpal-project-page3">
      {/* <SubmitForm activity="penpal" /> */}
      <PenpalForm lang={lang}/>
      <div className="up-arrow" />
    </div>
  );
};

export default PenpalProject_page3;
