import React from "react";
import { useSpring, animated } from "react-spring";
import "./style.css";
import "./paper.css";
import { Container, Row, Col } from "react-bootstrap";

/**
 * @author
 * @function PenpalProject_page1
 **/

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 3 - 250}px,${y / 3 - 200}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;
const trans5 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans6 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans7 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;
const trans8 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;
const trans9 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans10 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans11 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;
const trans12 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;
const trans13 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;

const PenpalProject_page1 = () => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  return (
    <div className="out penpal-project-page1 fade-in">
      <div className="paper-container">
        <div
          onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
        >
          <Container fluid className="paper-container-center">
            <Row className="row">
              <Col className="col" xs={2} sm={2} md={2} lg={2}>
                <animated.div
                  className="shredded-paper"
                  id="paper-3-small"
                  style={{ transform: props.xy.interpolate(trans5) }}
                >
                  <img src={require("../images/paper/3-small.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={3} sm={3} md={3} lg={3}>
                <animated.div
                  className="shredded-paper"
                  id="paper-7-green"
                  style={{ transform: props.xy.interpolate(trans10) }}
                >
                  <img src={require("../images/paper/7-green.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={2} sm={2} md={2} lg={2}>
                <animated.div
                  className="shredded-paper"
                  id="paper-6-yellow"
                  style={{ transform: props.xy.interpolate(trans9) }}
                >
                  <img src={require("../images/paper/6-yellow.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={3} sm={3} md={3} lg={3}>
                <animated.div
                  className="shredded-paper"
                  id="paper-8"
                  style={{ transform: props.xy.interpolate(trans12) }}
                >
                  <img src={require("../images/paper/8.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={2} sm={2} md={2} lg={2}>
                <animated.div
                  className="shredded-paper"
                  id="paper-3-large"
                  style={{ transform: props.xy.interpolate(trans4) }}
                >
                  <img src={require("../images/paper/3-large.png")} alt="" />
                </animated.div>
              </Col>
            </Row>
            <Row className="row">
              <Col className="col" xs={5} sm={5} md={5} lg={5}>
                <animated.div
                  className="shredded-paper"
                  id="paper-4"
                  style={{ transform: props.xy.interpolate(trans6) }}
                >
                  <img src={require("../images/paper/4.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={2} sm={2} md={2} lg={2}></Col>
              <Col className="col" xs={3} sm={3} md={3} lg={3}></Col>
              <Col className="col" xs={2} sm={2} md={2} lg={2}>
                <animated.div
                  className="shredded-paper"
                  id="paper-2"
                  style={{ transform: props.xy.interpolate(trans3) }}
                >
                  <img src={require("../images/paper/2.png")} alt="" />
                </animated.div>
              </Col>
            </Row>
            <Row className="row">
              <Col className="col" xs={2} sm={2} md={2} lg={2}>
                <animated.div
                  className="shredded-paper"
                  id="paper-1-orange"
                  style={{ transform: props.xy.interpolate(trans1) }}
                >
                  <img src={require("../images/paper/1-orange.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={3} sm={3} md={3} lg={3}>
                <animated.div
                  className="shredded-paper"
                  id="paper-9"
                  style={{ transform: props.xy.interpolate(trans13) }}
                >
                  <img src={require("../images/paper/9.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={1} sm={1} md={1} lg={1}>
                <animated.div
                  className="shredded-paper"
                  id="paper-1-pink"
                  style={{ transform: props.xy.interpolate(trans2) }}
                >
                  <img src={require("../images/paper/1-pink.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={3} sm={3} md={3} lg={3}>
                <animated.div
                  className="shredded-paper"
                  id="paper-5"
                  style={{ transform: props.xy.interpolate(trans7) }}
                >
                  <img src={require("../images/paper/5.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={1} sm={1} md={1} lg={1}>
                <animated.div
                  className="shredded-paper"
                  id="paper-7-orange"
                  style={{ transform: props.xy.interpolate(trans11) }}
                >
                  <img src={require("../images/paper/7-orange.png")} alt="" />
                </animated.div>
              </Col>
              <Col className="col" xs={2} sm={2} md={2} lg={2}>
                <animated.div
                  className="shredded-paper"
                  id="paper-6-green"
                  style={{ transform: props.xy.interpolate(trans8) }}
                >
                  <img src={require("../images/paper/6-green.png")} alt="" />
                </animated.div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* START OF MOBILE VERSION */}
      <div className="m-paper-container">
        <div className="m-paper">
          <img src={require("../images/paper/3-small.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/7-green.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/7-orange.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/8.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/5.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/6-yellow.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/2.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/1-orange.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/4.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/1-pink.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/3-large.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/9.png")} alt="" />
        </div>
        <div className="m-paper">
          <img src={require("../images/paper/6-green.png")} alt="" />
        </div>
      </div>
      {/* END OF MOBILE VERSION */}
      <div className="down-arrow" />
    </div>
  );
};

export default PenpalProject_page1;
