import React from "react";
import "./style.css";
// import SubmitForm from "../../SubmitForm";
import AudioExchangeForm from '../../SubmitForm/AudioExchangeForm'
import DelayLink from "../../DelayLink";
import pageTransition from "../../pageTransition";

/**
 * @author
 * @function SoundExchange_page3
 **/

const SoundExchange_page3 = ({lang}) => {
  return (
    <div className="sound-exchange-page3">
      <AudioExchangeForm lang={lang}/>
      {/* <SubmitForm activity="sound" /> */}
      <div className="down-arrow" />
    </div>
  );
};

export default SoundExchange_page3;
