import React, { useState } from "react";
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import _ from 'lodash'
import "./style.css";

const PenpalForm = ({lang}) => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isDragging, setDragging] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitFinish, setSubmitFinish] = useState(false)
  const [error, setError] = useState()

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    minSize: 0,
    maxSize: 5242880,
    multiple: false,
    onDragEnter: () => setDragging(true),
    onDragLeave: () => setDragging(false),
    onDrop: acceptedFiles => {
      setDragging(false)
      setError({
        ...error, 
        ...{ image: null }
      })
    }
  })

  const files = acceptedFiles && acceptedFiles.length ? acceptedFiles.map(file => (file.path))
  :
  lang == 'zh' ? '未選擇任何檔案' : 'No file is selected'

  const validateSubmit = () => {
    const emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const obj = { name, email }
    let errorObj = null
    _.each(obj, (value, key) => {
        if(value === '')
          errorObj = {
            ...errorObj,
            ...{
              [key]: lang == 'zh' ? '請填上資料' : 'Please fill in the field'
            }
          }
    })
    if(!emailFormat.test(String(email).toLowerCase()))
      errorObj = {
        ...errorObj,
        ...{
          email: lang == 'zh' ? '電郵地址格式錯誤' : 'Email address format is incorrect'
        }
      }
    if(!acceptedFiles.length)
    {
      errorObj = {
        ...errorObj,
        ...{
          image: lang == 'zh' ? '請上傳圖片' : 'Please upload photo'
        }
      }
    }
    if(errorObj)
    {
      console.log('errors', errorObj)
      setError(errorObj)
      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('going to submit form')

    if(validateSubmit()) {
      setIsSubmitting(true)
      console.log('no errors')
      const formHTML = e.target
      var formData = new FormData(formHTML)
      formData.append('image', acceptedFiles[0])
      formData.append('form', 'penpalForm')
      const response = await axios.post('http://l-sub.org/still-life-objects/backend/controllers/submitForm.php', formData, {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      if(response.status === 200)
      {
        setIsSubmitting(false)
        setSubmitFinish(true)
      }
    }
  } 

  return (
      <form id="penpalForm" onSubmit={handleSubmit}>
        <div className="submit-container">
          <p className="title">{lang == 'zh' ? '上載你的撕紙治療作品' : 'Upload your Paper-tearing photo' }</p>
          <div className="submit-form-container">
            {!submitFinish ?
              <div className="form-container">
                <div className={`file-container ${isDragging && 'isDragging'} ${error && error.image ? 'error' : null}`}>
                  <div {...getRootProps({className: 'dropzone'})}>
                    <input id="file" type="file" className="foo" {...getInputProps()} />
                    <div id="fileName">{files}</div>
                    <div className="form-des">
                      <span className="form-text form-title">{lang == 'zh' ? '檔案格式' : 'File format'}</span>
                      <div className="form-text">JPG, GIF, PNG, HEIC</div>
                    </div>
                    <div className="form-des">
                      <span className="form-text form-title">{lang == 'zh' ? '檔案大小' : 'File size'}</span>
                      <div className="form-text">{lang == 'zh' ? '不大於5mb' : 'Within 5mb'}</div>
                    </div>
                  </div>
                  {error && error.image ?
                  <div className="help-block">{error.image}</div>
                  : null}
                </div>
                <div className="input-container">
                  <div className={`input-label ${error && error.name ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '你的名字' : 'Name'}<sup>*</sup></label>
                    <input type="text" name="name" className="form-control" id="inputName" onChange={e => { setError({ ...error, ...{ name: null}}); setName(e.target.value)}}/>
                    {error && error.name ?
                    <div className="help-block">{error.name}</div>
                    :null}
                  </div>
                  <div className={`input-label ${error && error.email ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '電郵' : 'Email'}<sup>*</sup></label>
                    <input type="text" name="email" className="form-control" id="inputEmail" onChange={e => { setError({ ...error, ...{ email: null}}); setEmail(e.target.value)}}/>
                    {error && error.email ?
                    <div className="help-block">{error.email}</div>
                    :null}
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn-sumbit">
                      <span id="txt-beforeUpload">
                        {isSubmitting ? 
                          lang == 'zh' ? '上載中...' : 'Uploading...'
                        : 
                          lang == 'zh' ? '確認上載' : 'Upload'}
                      </span>
                  </button>
                </div>
              </div>
              :
              <div className="finish-container">
                  <div>{lang == 'zh' ? '你已成功上傳' : 'Upload successfully'}</div>
              </div>
            }
          </div>
        </div>
      </form>
  )
}

export default PenpalForm