import React, { useEffect, useState } from "react";
import "./style.css";
import { gsap } from "gsap";
import { Container, Row, Col } from "react-bootstrap";

/**
 * @author
 * @function SoundExchange_page2
 **/

const SoundExchange_page2 = (props) => {
  const { lang, fullpageprops } = props
  const [isShowDes, setIsShowDes] = useState(true);

  useEffect(() => {});

  const addDescription = () => {
    if (isShowDes && window.innerWidth > 1200) {
      gsap.fromTo(
        ".description",
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 1 }
      );
      setIsShowDes(false);
    }
  };

  return (
    <div className="sound-exchange-page2" onMouseOver={addDescription}>
      <div className="view">
        <Container fluid="md">
          <Row>
            <Col
              className="col description-col"
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <div className="description">
                  {lang == 'zh' ?
                  <div className="chinese">
                    <h4 className="programme-title">「睡前一小時聲音交換計劃」</h4>
                    <h4 className="programme-title-2">—心安，來自臨睡前無憂無慮的片刻</h4>
                      <p className="programme-intro">每一個晚上，你是如何趕上通往夢鄉的列車？香港藝術家團隊 L sub以「心安之物」於港日兩地推行「睡前一小時聲音交換計劃」的公眾活動。香港家庭與日本家庭的錄音配對交換，細聽別人的寢前片刻以作心靈連結。</p>
                      <p className="programme-intro">我們邀請獨居或來自任何家庭組合的你：</p>
                      <ol className="programme-intro">
                        <li>睡前大約一小時，把手機／錄音機放在家中活動範圍的一角，開啟錄音；可設鬧鐘作提醒。</li>
                        <li>不用刻意，繼續如常作息。</li>
                        <li>一小時後，暫停錄音，拍下一張此刻家中環境的照片。</li>
                        <li>翌日 ，請把錄音、相片、姓名、年齡、居住地區和總結那天的一句話<a onClick={() => fullpageprops.moveTo(4)}>傳予L sub</a></li>
                      </ol>
                      <p className="programme-intro">謝謝你們的參與！晚安！</p>
                    </div>
                    :
                    <div className="english">
                      <h4 className="programme-title">‘1-hour Before Sleep Audio Exchange'</h4>
                      <h4 className="programme-title-2">— A peace of mind from a carefree moment before bed. </h4>
                        <p className="programme-intro">What’s your bedtime routine for a good night’s sleep? Hong Kong artist collective L sub launched a public campaign, ‘1-hour Before Sleep Audio Exchange’, between both Hong Kong and Japanese communities. Through the exchange, you may pursue a spiritual connection while listening to others’ before sleep audio record.</p>
                        <p className="programme-intro">No matter you are living alone or with a family, you are invited to join this campaign:</p>
                        <ol className="programme-intro">
                          <li>Put your mobile phone/recorder in a corner of your apartment about one hour before going to bed. Start recording and you may set an alarm clock as a reminder.</li>
                          <li>Continue to work and rest as usual.</li>
                          <li>After one hour, pause the recording and take a picture of the environment at the moment.</li>
                          <li>On the next day, please send the following to <a onClick={() => fullpageprops.moveTo(4)}>L sub</a>: the recording, the picture of your home, name, age, residing district and a short phrase to conclude that day. (still.life.objects@gmail.com)</li>
                        </ol>
                        <p className="programme-intro">Thank you for your participation! Good night!</p>
                      </div>
                    }
              </div>
            </Col>
          </Row>
        </Container>
        <div className="wapper-container">
          <div id="wave-top wave">
            <img
              id="wave-top-img"
              src={require("../images/wave/wave1.png")}
              width="100%"
              alt=""
            />
          </div>
          <div id="wave-bottom wave">
            <img
              id="wave-bottom-img"
              src={require("../images/wave/wave2.png")}
              width="100%"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="down-arrow" />
    </div>
  );
};

export default SoundExchange_page2;
