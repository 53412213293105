import React, { useState } from "react";
import "./style.css";
import { gsap } from "gsap";
import { Container, Row, Col } from "react-bootstrap";

/**
 * @author
 * @function DonateHeart_page2
 **/

const DonateHeart_page2 = (props) => {
  const { fullpageprops, lang } = props
  const [isShowDes, setIsShowDes] = useState(true);
  const addDescription = () => {
    if (isShowDes) {
      gsap.fromTo(
        ".description",
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 1, delay: 1 }
      );
      setIsShowDes(false);
    }
  };
  return (
    <div className="donate-heart-page2" onMouseOver={addDescription}>
      <div className="view">
        <Container fluid="md">
          <Row className="description-row">
            <Col
              className="col description-col"
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <div className="description">
                {lang == 'zh' ?
                <div className="chinese">
                  <h4 className="programme-title">「摺銀紙＝做慈善」計劃</h4>
                  <h4 className="programme-title-2">—心安，來自無私的施予</h4>
                  <p className="programme-intro">
                    把每次飢餓化作令人飽足的力量。香港藝術家團隊L sub以「心安之
                    物」現於港日兩地推行「摺銀紙＝做慈善」的運動。把你日常生活中
                    一個偶然的小動作，化作善心的來源，積少成多，用你的剩餘補足別
                    人的缺乏。
                  </p>
                  <p className="programme-intro">任何人都可以來分享這份喜悅：</p>
                  <ol className="programme-list">
                    <li>
                      當你每次感到肚餓時，請拿出銀包內一張銀紙對摺（作記號），再放回。
                    </li>
                    <li>
                      兩星期點算一次，把對摺的錢全數捐給有需要的人或機構，養成習慣。
                    </li>
                    <li>
                      行多步，多一倍！若你把對摺的銀紙排列拍照，<a onClick={() => fullpageprops.moveTo(4)}>傳予L sub</a>，我們會把同等的金額*捐給有需要的機構。
                    </li>
                  </ol>
                  <p className="programme-intro">施比受更有福！願你平安!<br/>
                  #L sub在此活動的配對捐出上限為$12,000，資助周先生之「一樽涼水」計劃，為受惠者贈予膳食之餘為他們多送一枝水。
                  </p>
                </div>
                :
                <div className="english">
                  <h4 className="programme-title">‘Fold a Banknote. Twice as Good'</h4>
                  <h4 className="programme-title-2">—A peace of mind from the selfless giving.</h4>
                  <p className="programme-intro">
                  To turn every hunger into a satisfying force, Hong Kong artist collective L sub developed a public campaign, ‘Fold a Banknote. Twice as Good', between both Hong Kong and Japanese communities. The campaign introduces a way to live a humble life by turning a small action in your daily life into a source of kindness.
                  </p>
                  <p className="programme-intro">Let’s accumulate less into more, and use your surplus to make up for the lack of others. All are welcome to join this campaign to share the joy of giving: </p>
                  <ol className="programme-list">
                    <li>
                    When you feel hungry, take out a banknote from your wallet and fold it in middle (create a marking), then put it back
                    </li>
                    <li>
                    Count the saving once every two weeks, the money will be donated to the needy or charitable organisations. Make this action as a habit. 
                    </li>
                    <li>
                    Share a picture of your folded banknotes with <a onClick={() => fullpageprops.moveTo(4)}>L sub</a> (still.life.objects@gmail.com) and they will contribute the same amount for charitable purposes in return.
                    </li>
                  </ol>
                  <p className="programme-intro">It is more blessed to give than to receive!<br/>
                  Wish you all the best!
                  </p>
                </div>
                }
              </div>
            </Col>
          </Row>
        </Container>

        <div>
          <img
            className="money-2 fpaper"
            id="fpaper-01-p2"
            src={require("../images/money/fpaper-02.png")}
            alt=""
          />
        </div>
        <div>
          <img
            className="money-2 fpaper"
            id="fpaper-02-p2"
            src={require("../images/money/fpaper-03.png")}
            alt=""
          />
        </div>
        <div>
          <img
            className="money-2 fpaper"
            id="fpaper-03-p2"
            src={require("../images/money/fpaper-04.png")}
            alt=""
          />
        </div>
        <div>
          <img
            className="money-2 fpaper"
            id="fpaper-04-p2"
            src={require("../images/money/fpaper-06.png")}
            alt=""
          />
        </div>
      </div>
      <div className="down-arrow" />
    </div>
  );
};

export default DonateHeart_page2;
