import React, { useEffect, useState } from "react";
import DelayLink from "../DelayLink";
import "./style.css";
/**
 * @author
 * @function Navbar
 **/

const Navbar = ({lang, handleSwitchLang}) => {
  const currentURL = window.location.href;
  const target = currentURL.split("/")[4]; // penpal-project or sound-exchange or donate-heart
  const [activity, setActivity] = useState(target);

  useEffect(() => {
    // console.log(props)
    if (target == undefined || target == null || target == "") {
      setActivity("landing");
    }
  });

  const goTo = (page) => {
    setActivity(page);
    // props.history.push({
    //   pathname: `/still-life-objects/${page}/page1`
    // })
  };

  const switchLang = () => {
    handleSwitchLang()
  }

  return (
    <div>
      <div id="landing">
        <div
          id="nav-item-landing"
          onClick={() => goTo("landing")}
          className={
            activity === "landing" ? "nav-item-selected" : "nav-item"
          }
        >
          <div
            className={
              activity === "landing" ? "nav-line-selected" : "nav-line"
            }
          />
          <DelayLink delay={0} to="/still-life-objects/">
          {lang == 'zh' ? '心安之物' : 'Still Life Objects'}
          </DelayLink>
        </div>
      </div>
      <div id="exhibitions">
        <div
          id="nav-item-exhibitions"
          onClick={() => goTo("festival")}
          className={"nav-item"}
        >
          <div
            className={"nav-line"}
          />
          <DelayLink external={true} delay={0} to="http://l-sub.org/still-life-objects-exhibition/">
          {lang == 'zh' ? '周末禮讚' : 'Interactive Exhibition'}
          </DelayLink>
        </div>
      </div>
      <div id="ten-days">
        <div
          id="nav-item-ten-days"
          onClick={() => goTo("festival")}
          className={ "nav-item"}
        >
          <div
            className={"nav-line"}
          />
          <DelayLink external={true} delay={0} to="http://l-sub.org/still-life-objects-festival/">
          {lang == 'zh' ? '十日祭' : 'Ten-day Festival'}
          </DelayLink>
        </div>
      </div>
      {/* <div id="exhibitions">
        <div
          id="nav-item-exhibitions"
          onClick={() => goTo("http://l-sub.org/still-life-objects-festival/")}
          className={"nav-item"}
        >
          <div
            className={"nav-line"}
          />
          <DelayLink external={true} delay={0} to="http://l-sub.org/still-life-objects-exhibition/">
          {lang == 'zh' ? '與展場互動' : 'Interactive Exhibition'}
          </DelayLink>
        </div>
      </div> */}
      <div id="penpal-project">
        <div
          id="nav-item-penpal-project"
          onClick={() => goTo("penpal-project")}
          className={
            activity === "penpal-project" ? "nav-item-selected" : "nav-item"
          }
        >
          <div
            className={"nav-line"}
          />
          <DelayLink delay={0} to="/still-life-objects/paper-tearing-penpal-therapy">
            {lang == 'zh' ? '撕紙治療（筆友）計劃' : 'Paper Tearing Pen-pal Therapy'}
          </DelayLink>
        </div>
      </div>
      <div id="sound-exchange">
        <div
          id="nav-item-sound-exchange"
          onClick={() => goTo("sound-exchange")}
          className={
            activity === "sound-exchange" ? "nav-item-selected" : "nav-item"
          }
        >
          <div
            className={
              activity === "sound-exchange" ? "nav-line-selected" : "nav-line"
            }
          />
          <DelayLink delay={0} to="/still-life-objects/1-hour-before-sleep-audio-exchange">
          {lang == 'zh' ? '睡前一小時聲音交換' : '1-hour Before Sleep Audio Exchange'}
          </DelayLink>
        </div>
      </div>
      <div id="donate-heart">
        <div
          id="nav-item-donate-heart"
          onClick={() => goTo("donate-heart")}
          className={
            activity === "donate-heart" ? "nav-item-selected" : "nav-item"
          }
        >
          <div
            className={
              activity === "donate-heart" ? "nav-line-selected" : "nav-line"
            }
          />
          <DelayLink delay={0} to="/still-life-objects/fold-a-banknote-twice-as-good">
            {lang == 'zh' ? '摺銀紙＝做慈善' : 'Fold a Banknote. Twice as Good'}
          </DelayLink>
        </div>
      </div>
      <div id="change-lang">
        <div className="nav-item">
          <div className="nav-line"></div>
            <a onClick={switchLang}>{lang == 'zh' ? 'Eng' : '中文'}</a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
