import React from 'react'

export default function archive_element({ props }) {
    const { img, name, area, line1, line2, audio } = props;
    return (
        <div className='element-container'>
            <img className="archive-Img" data-src={img} alt="" />
            <div className="info">
                <div className="name">{name}</div>
                <div className="area">{area}</div>
            </div>
            <div className="lines">
                <div className="line1">{line1}</div>
                <div className="line2">{line2}</div>
            </div>
            <audio controls className="audio" onPlay={handlePlay} controlsList="nodownload" onPause={() => { window.$_currentlyPlaying = undefined }}>
                <source data-src={audio} />
            </audio>
        </div>
    )
}

//Pause other video before playing the current
const handlePlay = (e) => {
    if(window.$_currentlyPlaying) 
    {
        window.$_currentlyPlaying.pause();
    }
    window.$_currentlyPlaying = e.target;
}