import React, { useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import PenpalProject_page1 from "./page1";
import PenpalProject_page2 from "./page2";
import PenpalProject_page2a from "./page2a";
import PenpalProject_page3 from "./page3";
import "./style.css";

const PenpalProject = ({lang}) => {
  useEffect(() => {
    if (window.innerWidth < 762) {
      console.log(window.innerWidth);
    }
  });

  return (
    <ReactFullpage
      //fullpage options
      licenseKey={"YOUR_KEY_HERE"}
      scrollingSpeed={1000} /* Options here */
      responsiveWidth={1200}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <PenpalProject_page1 />
            </div>
            <div className="section">
              <PenpalProject_page2 fullpageprops={fullpageApi} lang={lang}/>
            </div>
            <div className="section">
              <PenpalProject_page2a lang={lang}/>
            </div>
            <div className="section">
              <PenpalProject_page3 lang={lang}/>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default PenpalProject;
