const elements = [
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-01.jpg',
        name: '中澤友也',
        area: '津南', 
        line1: '静かに寝ているし、おれたちも寝るか', 
        line2: '我靜靜地睡著了，大家也一樣嗎？', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-01-%E4%B8%AD%E6%BE%A4%E5%8F%8B%E4%B9%9F_TomoyaNAKAZAWA.MP3',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-02.jpg',
        name: '大関惇',
        area: '津南', 
        line1: '寝る前に飲む一杯が最高', 
        line2: '睡前飲返杯，一流！', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-02%E5%A4%A7%E9%96%A2%E6%B7%B3_JunOSEKI.mp3',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-03.jpg',
        name: '小野塚賢人',
        area: '津南', 
        line1: '今日も1日お疲れ様', 
        line2: '今日都辛苦了一整天呢', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-03-%E5%B0%8F%E9%87%8E%E5%A1%9A%E8%B3%A2%E4%BA%BA_KentoONOZUKA.WAV',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-04.jpg',
        name: '山岸麗好',
        area: '津南', 
        line1: '今日も1日がんばったなぁ', 
        line2: '今日整天都很努力呀', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-04-%E5%B1%B1%E5%B2%B8%E9%BA%97%E5%A5%BD_ReikoYAMAGISHI.MP3',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-05.jpg',
        name: '福原敏史',
        area: '津南', 
        line1: 'あした、またひとつ大きく', 
        line2: '明天勝今天', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-05-%E7%A6%8F%E5%8E%9F%E6%95%8F%E5%8F%B2_ToshifumiFUKUHARA.mp3',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-06.jpg',
        name: '尼克遜 Nixon',
        area: '香港', 
        line1: '浮浮沉沉，過得一日得一日。', 
        line2: '浮き沈みもあるけど、何とかして一日を過ごせばそれでいいです。', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-06-Nixon.m4a',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-07.jpg',
        name: '吉',
        area: '香港', 
        line1: '蚊子很討厭，卻讓全家都團結起來。', 
        line2: '蚊は嫌われ者ですが、家族一丸となる時間をつくってくれます。', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-07-%E5%90%89gut.mp3',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-08.jpg',
        name: 'Choy Po Yin',
        area: '香港', 
        line1: '有時夜晚就這樣去了。', 
        line2: '時々、夜そのまま過ごしてしまうこともありました。', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-08-Bobo+Choy.m4a',
    },
    {
        //no img
        // img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-09.jpg',
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/placeholder.png',
        name: "Au's family",
        area: '香港', 
        line1: '快啲瞓啦', 
        line2: '早く寝てくださいね。', 
        audio: '',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-10.jpg',
        name: 'Nicole',
        area: '香港', 
        line1: 'Today, I’ve already done my best to utilize myself to benefit others.', 
        line2: '今日も一日、人のために全力を尽くしました。', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-10-Nicole.mp4',
    },
    {
        //no img
        //img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-11.jpg',
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/placeholder.png',
        name: 'RW',
        area: '香港', 
        line1: '但願我們以後還能五秒入睡', 
        line2: 'これからも5秒で寝落ちできればいいなぁ。', 
        audio: '',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-12.jpg',
        name: '曾慧明',
        area: '香港', 
        line1: '倦了就要休息喔！', 
        line2: '疲れきった時は、早くお休みくださいね！', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-12-TSM%E5%85%83%E5%B7%9E%E8%A1%97.m4a',
    },
    {
        //no img
        // img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-13.jpg',
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/placeholder.png',
        name: 'FC',
        area: '香港', 
        line1: '等她喊過吃過奶後才覺得一天終於完結', 
        line2: '彼女はミルクを飲むと、一日がやっと終わると感じます。', 
        audio: '',
    },
    //TODO
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-14.jpg',
        name: 'Fanny Yeung',
        area: '香港', 
        line1: '到瞓覺時間，可以瞓落床，好幸福呀', 
        line2: '夜ベッドに入って眠りにつくと、ほんとに幸せ！', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-14-Fanny.mp3',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-15.jpg',
        name: 'Anna Mak',
        area: '香港', 
        line1: '又一天了', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-15-Anna+Mak.mp4',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-16.jpg',
        name: '劉政熙',
        area: '香港', 
        line1: '平靜的周末', 
        line2: '', 
        audio: '',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-17.jpg',
        name: 'Candy Wo',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-17-candy+wo.mp4',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-18.jpg',
        name: 'Scott Leung',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-18-SCOTT.m4a',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-19.jpg',
        name: 'Carlo Chiu',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-19-Carlo+Chiu.mp4',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-20.jpg',
        name: 'Fat Lam',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-20-FatLam.mp4',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-21.jpg',
        name: 'Angel He',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-21-Angel+He.mp4',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-22.jpg',
        name: 'Bowie Leung',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-22-Bowie+Leung.mov',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-23.jpg',
        name: 'Alan He',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-23-He+Yang+(Alan).mp3',
    },
    {
        img: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/images/1hour-24.jpg',
        name: 'Cami Hui',
        area: '香港', 
        line1: '', 
        line2: '', 
        audio: 'https://still-life-objects-entry.s3.ap-east-1.amazonaws.com/audio/1hour-24-Cami+Hui.m4a',
    },
]
export default elements;