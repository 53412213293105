import React from 'react'
import './style.css'
import DelayLink from '../../DelayLink'
import pageTransition from '../../pageTransition'

/**
* @author
* @function DonateHeart_page1
**/

const DonateHeart_page1 = (props) => {
  return (
    <div className="donate-heart-page1">
      <div className="money-group">
        <div><img className="money" id="fpaper-01" src={require('../images/money/fpaper-01.png')} alt="" /></div>
        <div><img className="money" id="fpaper-02" src={require('../images/money/fpaper-02.png')} alt="" /></div>
        <div><img className="money" id="fpaper-03" src={require('../images/money/fpaper-03.png')} alt="" /></div>
        <div><img className="money" id="fpaper-04" src={require('../images/money/fpaper-04.png')} alt="" /></div>
        <div><img className="money" id="fpaper-05" src={require('../images/money/fpaper-05.png')} alt="" /></div>
        <div><img className="money" id="fpaper-06" src={require('../images/money/fpaper-06.png')} alt="" /></div>
        <div><img className="money" id="fpaper-07" src={require('../images/money/fpaper-07.png')} alt="" /></div>
        <div><img className="money" id="fpaper-08" src={require('../images/money/fpaper-04.png')} alt="" /></div>
      </div>
      <div className="m-money-group">
        <div><img className="money" id="fpaper-01" src={require('../images/money/fpaper-01.png')} alt="" /></div>
        <div><img className="money" id="fpaper-02" src={require('../images/money/fpaper-02.png')} alt="" /></div>
        <div><img className="money" id="fpaper-03" src={require('../images/money/fpaper-03.png')} alt="" /></div>
        <div><img className="money" id="fpaper-04" src={require('../images/money/fpaper-04.png')} alt="" /></div>
        <div><img className="money" id="fpaper-05" src={require('../images/money/fpaper-05.png')} alt="" /></div>
        <div><img className="money" id="fpaper-06" src={require('../images/money/fpaper-06.png')} alt="" /></div>
        <div><img className="money" id="fpaper-07" src={require('../images/money/fpaper-07.png')} alt="" /></div>
        <div><img className="money" id="fpaper-08" src={require('../images/money/fpaper-04.png')} alt="" /></div>
      </div>
      <div className="down-arrow" />
    </div>
  )
}


export default DonateHeart_page1