import React from "react";
import "./style.css";
import DelayLink from "../../DelayLink";
import pageTransition from "../../pageTransition";
import DonateHeartForm from '../../SubmitForm/DonateHeartForm'

/**
 * @author
 * @function DonateHeart_page3
 **/

const DonateHeart_page3 = ({lang}) => {
  return (
    <div className="donate-heart-page3">
      {/* <SubmitForm activity="donate" /> */}
      <DonateHeartForm lang={lang}/>
      <div className="up-arrow" />
    </div>
  );
};

export default DonateHeart_page3;
