import React, { useEffect, useState } from "react";
import "./style.css";
import { Row } from "react-bootstrap";

/**
 * @author
 * @function SoundExchange_page1
 **/

const SoundExchange_page1 = (props) => {
  const [soundState, setSoundState] = useState(true);
  const playAudio = () => {
    document.getElementById("audio").play();
  };
  const pauseAudio = () => {
    document.getElementById("audio").pause();
  };

  useEffect(() => {
    if (soundState) {
      playAudio();
    } else {
      pauseAudio();
    }
  });

  const turnOnOff = () => {
    setSoundState(!soundState);
  };

  return (
    <div className="sound-exchange-page1">
      <div className="sound-icon" onClick={turnOnOff}>
        <SoundSVG sound={soundState} />
        <Stop_SoundSVG sound={soundState} />
        <audio id="audio">
          <source
            src={require("../sound/whitenoise.mp3")}
            type="audio/mpeg"
          ></source>
        </audio>
      </div>
      <div className="sliding-container">
        <Row className="row-sliding">
          <img src={require("../images/wave/wave-1.png")} alt="" width="100%" />
        </Row>
        <Row className="row-sliding second">
          <img src={require("../images/wave/wave-2.png")} alt="" width="100%" />
        </Row>
      </div>
      <div className="m-sliding-container">
        <div><img src={require("../images/wave/wave-1.png")} alt="" width="100%" /></div>
        <div><img src={require("../images/wave/wave-2.png")} alt="" width="100%" /></div>
      </div>
      <div className="down-arrow" />
    </div>
  );
};

const SoundSVG = (props) => {
  return (
    <div className={props.sound ? "sound-svg-on" : "sound-svg-off"}>
      <svg
        id="equalizer"
        width="40px"
        height="28px"
        viewBox="0 0 10 7"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <g fill="#000000">
          <rect
            id="bar1"
            transform="translate(0.500000, 6.000000) rotate(180.000000) translate(-0.500000, -6.000000) "
            x="0"
            y="5"
            width="1"
            height="2px"
          ></rect>
          <rect
            id="bar2"
            transform="translate(3.500000, 4.500000) rotate(180.000000) translate(-3.500000, -4.500000) "
            x="3"
            y="2"
            width="1"
            height="5"
          ></rect>
          <rect
            id="bar3"
            transform="translate(6.500000, 3.500000) rotate(180.000000) translate(-6.500000, -3.500000) "
            x="6"
            y="0"
            width="1"
            height="7"
          ></rect>
          <rect
            id="bar4"
            transform="translate(9.500000, 5.000000) rotate(180.000000) translate(-9.500000, -5.000000) "
            x="9"
            y="3"
            width="1"
            height="4"
          ></rect>
        </g>
      </svg>
    </div>
  );
};

const Stop_SoundSVG = (props) => {
  return (
    <div className={!props.sound ? "sound-svg-on" : "sound-svg-off"}>
      <svg
        id="equalizer"
        width="40px"
        height="28px"
        viewBox="0 0 10 7"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <g fill="#000000">
          <rect
            transform="translate(0.500000, 6.000000) rotate(180.000000) translate(-0.500000, -6.000000) "
            x="0"
            y="5"
            width="1"
            height="2px"
          ></rect>
          <rect
            transform="translate(3.500000, 4.500000) rotate(180.000000) translate(-3.500000, -4.500000) "
            x="3"
            y="2"
            width="1"
            height="5"
          ></rect>
          <rect
            transform="translate(6.500000, 3.500000) rotate(180.000000) translate(-6.500000, -3.500000) "
            x="6"
            y="0"
            width="1"
            height="7"
          ></rect>
          <rect
            transform="translate(9.500000, 5.000000) rotate(180.000000) translate(-9.500000, -5.000000) "
            x="9"
            y="3"
            width="1"
            height="4"
          ></rect>
        </g>
      </svg>
    </div>
  );
};

export default SoundExchange_page1;
