import React, { useState } from "react"
import ReactPlayer from 'react-player/youtube'
import './style.css'

const Landing = () => {
    return (
        <div className="landing">
            <div className="video-background">
                <div className="video-foreground">
                    <ReactPlayer url='https://www.youtube.com/watch?v=fmLxSujYEmI-U' 
                    playing={true}
                    muted={true}
                    loop={true} 
                    width="100%" 
                    height="100vh"
                    />
                </div>
            </div>
        </div>
    )
}

export default Landing