import React from 'react';
import './style.css';
import './overrides.css';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import Slider from './slider';

function PenpalProject_page2a({lang}) {
    return (
        <div className="penpal-project-page2a">
            <Container>
                <Row id="row-carousel">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} id="col-carousel" className="col">
                        <Slider id="slider" />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className="slo-content-parabox col">
                        {lang == 'zh' ?
                        <div className="chinese">
                            <h4 className="slo-content-h4">唐小姐的C紙人生</h4>
                            <div className="slo-content-divider"></div>
                            <p className="slo-content-p">微涼冬日法院前，她穿著一身黑衫褲，嘴唇擦上薄薄的朱紅彩；刷上銀白甲油的手指拎著印有 Hello Kitty 的小袋子。她是唐小姐，退休人士，是孩子的媽，但不再是他的妻。無論寒冬還是炎夏，她經常出入大小法院，排隊旁聽一場又一場社運案件。她每次旁聽入場籌號色紙，儲起來現已是厚厚一疊彩虹，反映她的心神寄託、心安所在。</p>
                            <p className="slo-content-p">旁聽漸多，唐小姐認識一群同行者，路上有了伙伴，不再孤單一人。聽審漸多，唐小姐沒有變得麻木，還不時為青年被重判而感傷流淚。化淚（tears）為撕（tear），我們將唐小姐的籌號色紙和撕碎動作結合，將情緒轉化成行動，推出「C紙人生」筆友計劃，遙距地交換親密的人生。</p>
                            <p className="slo-content-p">我們撕出色彩斑駁的紙屑製成展場天花的大型裝置。從地下觀之，這似是重重黑影，有如人生將盡的陰霾隆隆襲於肩膊。要發現人生之美，我們需要多走一步。拾級而上，你會發現每片陰影背後都帶有繽紛的光彩。你人生是甚麼色彩？生活又是甚麼形狀？</p>
                        </div>
                        :
                        <div className="english">
                            <h4 className="slo-content-h4">The Paper-tearing Life of Ms Tong</h4>
                            <div className="slo-content-divider"></div>
                            <p className="slo-content-p">On a fresh winter day, Ms Tong, toting a small Hello Kitty bag, visits the courthouse dressed in black with a tad of red lipstick on her lips and silver polish on her fingernails. Retired and a single mother, she makes frequent visits to the courtrooms all year round, queuing up to hear cases after cases of social movements in the public gallery. </p>
                            <p className="slo-content-p">She saves the colourful number tickets for each visit, and has amassed quite a collection. Despite the large number of cases heard, Ms Tong has not been numbed; on the contrary, she often sheds tears for those who receive heavy sentences. Turning “tears” into “tear”, we take inspiration from Ms Tong’s colour number tickets and the action of tearing paper, and transform emotion into action.</p>
                            <p className="slo-content-p">That was how the activities, “Paper-tearing Life” workshop and “Paper Tearing Pen-pal Therapy” started, as participants conducted the exchange of intimate life experiences despite the geographical distances.</p>
                        </div>
                        }
                    </Col>
                </Row>
            </Container>
            <div className="down-arrow" />
        </div>
    )
}

export default PenpalProject_page2a;
