import React, { useState } from "react";
import axios from 'axios'
import Dropzone, {useDropzone} from 'react-dropzone'
import _ from 'lodash'
import "./style.css";

const AudioExchangeForm = ({lang}) => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('')
  const [age, setAge] = useState('')
  const [location, setLocation] = useState('')
  const [summary, setSummary] = useState('')
  const [audio, setAudio] = useState()
  const [image, setImage] = useState()
  const [isAudioDragging, setAudioDragging] = useState(false)
  const [isImageDragging, setImageDragging] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitFinish, setSubmitFinish] = useState(false)
  const [error, setError] = useState()

  const validateSubmit = () => {
    const emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const obj = { name, email, location, summary }
    let errorObj = null
    _.each(obj, (value, key) => {
        if(value === '')
          errorObj = {
            ...errorObj,
            ...{
              [key]: lang == 'zh' ? '請填上資料' : 'Please fill in the field'
            }
          }
    })
    if(!emailFormat.test(String(email).toLowerCase()))
      errorObj = {
        ...errorObj,
        ...{
          email: lang == 'zh' ? '電郵地址格式錯誤' : 'Email address format is incorrect'
        }
      }
    if(!audio)
    {
      errorObj = {
        ...errorObj,
        ...{
            audio: lang == 'zh' ? '請上傳聲音' : 'Please upload audio'
        }
      }
    }
    if(!image)
    {
      errorObj = {
        ...errorObj,
        ...{
          image: lang == 'zh' ? '請上傳圖片' : 'Please upload photo'
        }
      }
    }
    if(errorObj)
    {
      console.log('errors', errorObj)
      setError(errorObj)
      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('going to submit form')

    if(validateSubmit()) {
      setIsSubmitting(true)
      console.log('no errors')
      const formHTML = e.target
      var formData = new FormData(formHTML)
      formData.append('gender', gender)
      formData.append('audio', audio)
      formData.append('image', image)
      formData.append('form', 'audioExchangeForm')
      const response = await axios.post('http://l-sub.org/still-life-objects/backend/controllers/submitForm.php', formData, {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      if(response.status === 200)
      {
        setIsSubmitting(false)
        setSubmitFinish(true)
      }
    }
  } 

  return (
      <form id="audioExchangeForm" onSubmit={handleSubmit}>
        <div className="submit-container">
          <p className="title">{lang == 'zh' ? '上載你的睡前聲軌' : 'Upload your Before-bed Soundtrack'}</p>
          <div className="submit-form-container two-form">
            {!submitFinish ?
            <div>
              <div className="form-container">
                  <div className="row">
                    <div className={`file-container col-12 col-sm-6 ${isAudioDragging && 'isDragging'} ${error && error.audio ? 'error' : ''}`}>
                        <Dropzone accept="audio/wav, audio/mp3, audio/m4a, audio/wma, audio/aiff, audio/aac, audio/WAV, audio/MP3, audio/M4A, audio/WMA, audio/AIFF, audio/AAC" minSize={0} maxSize={52428800} multiple={false}
                            onDrop={acceptedFiles => {
                            setAudio(acceptedFiles[0])
                            setAudioDragging(false)
                            setError({
                              ...error, 
                              ...{ audio: null }
                            })
                         }} onDragEnter={() => setAudioDragging(true)} onDragLeave={() => setAudioDragging(false)}
                         >
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <div className="two-form-title">{lang == 'zh' ? '睡前聲軌' : 'Before-bed Soundtrack'}</div>
                                    <input id="file" type="file" className="foo" {...getInputProps()} />
                                    <div id="fileName">{audio && audio.name ? audio.name : '未選擇任何檔案'}</div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案格式' : 'File format'}</span>
                                        <div className="form-text">MP3, WAV</div>
                                    </div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案大小' : 'File size'}</span>
                                        <div className="form-text">{lang == 'zh' ? '不大於50mb' : 'Within 50mb'}</div>
                                    </div>
                                    {error && error.audio ?
                                    <div className="help-block">{error.audio}</div>
                                    : null}
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className={`file-container col-12 col-sm-6 ${isImageDragging && 'isDragging'} ${error && error.image ? 'error' : ''}`}>
                        <Dropzone accept="image/*" minSize={0} maxSize={5242880} multiple={false}
                            onDrop={acceptedFiles => {
                                setImage(acceptedFiles[0])
                                setImageDragging(false)
                                setError({
                                ...error, 
                                ...{ image: null }
                                })
                            }} onDragEnter={() => setImageDragging(true)} onDragLeave={() => setImageDragging(false)}
                            >
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <div className="two-form-title">{lang == 'zh' ? '一張家居角落相片' : 'A photo of your home'}</div>
                                    <input id="file" type="file" className="foo" {...getInputProps()} />
                                    <div id="fileName">{image && image.name ? image.name : lang == 'zh' ? '未選擇任何檔案' : 'No file is selected'}</div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案格式' : 'File format'}</span>
                                        <div className="form-text">JPG, GIF, PNG, HEIC</div>
                                    </div>
                                    <div className="form-des">
                                        <span className="form-text form-title">{lang == 'zh' ? '檔案大小' : 'File size'}</span>
                                        <div className="form-text">{lang == 'zh' ? '不大於5mb' : 'Within 5mb'}</div>
                                    </div>
                                    {error && error.image ?
                                    <div className="help-block">{error.image}</div>
                                    : null}
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </div>
                <div className="input-container">
                <div className={`input-label ${error && error.name ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '你的名字' : 'Your name'}<sup>*</sup></label>
                    <input type="text" name="name" className="form-control" id="inputName" onChange={e => { setError({ ...error, ...{ name: null}}); setName(e.target.value)}}/>
                    {error && error.name ?
                    <div className="help-block">{error.name}</div>
                    :null}
                  </div>
                  <div className={`input-label`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '性別' : 'Gender'}</label>
                    <div className="radio-group">
                        <input type="radio" name="gender" value="M" onChange={e => { setGender('M')}}/><span>{lang == 'zh' ? '男' : 'Male'}</span>
                        <input type="radio" name="gender" value="F" onChange={e => { setGender('F')}}/><span>{lang == 'zh' ? '女' : 'Female'}</span>
                        <input type="radio" name="gender" value="O" onChange={e => { setGender('O')}}/><span>{lang == 'zh' ? '不願透露' : 'Secret'}</span>
                    </div>
                </div>
                  <div className={`input-label`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '年齡' : 'Age'}</label>
                    <input type="text" name="age" className="form-control" id="inputName" onChange={e => { setAge(e.target.value)}}/>
                  </div>
                  <div className={`input-label ${error && error.location ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '居住區域' : 'Residential district' }<sup>*</sup></label>
                    <input type="text" name="location" className="form-control" id="inputName" onChange={e => { setError({ ...error, ...{ location: null}}); setLocation(e.target.value)}}/>
                    {error && error.location ?
                    <div className="help-block">{error.location}</div>
                    :null}
                  </div>
                  <div className={`input-label ${error && error.summary ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '總結這一天的一句話' : 'Summary of today'}<sup>*</sup></label>
                    <input type="text" name="summary" className="form-control" id="inputName" onChange={e => { setError({ ...error, ...{ summary: null}}); setSummary(e.target.value)}}/>
                    {error && error.summary ?
                    <div className="help-block">{error.summary}</div>
                    :null}
                  </div>
                  <div className={`input-label ${error && error.email ? 'error' : null}`}>
                    <label htmlFor="inputName" className="form-text">{lang == 'zh' ? '電郵' : 'Email'}<sup>*</sup></label>
                    <input type="text" name="email" className="form-control" id="inputEmail" onChange={e => { setError({ ...error, ...{ email: null}}); setEmail(e.target.value)}}/>
                    {error && error.email ?
                    <div className="help-block">{error.email}</div>
                    :null}
                  </div>
                </div>
              </div>
                <button type="submit" className="btn-sumbit">
                    <span id="txt-beforeUpload">
                        {isSubmitting ? 
                          lang == 'zh' ? '上載中...' : 'Uploading...'
                        : 
                          lang == 'zh' ? '確認上載' : 'Upload'}
                      </span>
                </button>
                </div>
              :
              <div className="finish-container">
                  <div>{lang == 'zh' ? '你已成功上傳' : 'Upload successfully'}</div>
              </div>
            }
          </div>
        </div>
      </form>
  )
}

export default AudioExchangeForm